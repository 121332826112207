<template id="sell.html">
	<v-ons-page id="sell">
		<v-ons-toolbar>
			<div
				class="left"
			>
				<v-ons-toolbar-button @click="openMenu">
					<v-ons-icon icon="md-menu" />
				</v-ons-toolbar-button>
			</div>
			<div class="center">
				Sell
			</div>
			<div
				class="right"
			>
				<v-ons-toolbar-button
					icon="md-search"
					@click="search"
				/>
				<v-ons-toolbar-button
					icon="md-camera"
					@click="scanItem"
				/>
				<v-ons-toolbar-button
					v-if="cart.items.length>0"
					icon="md-shopping-cart"
					class="position-relative"
					@click="showCart"
				>
					<span
						v-if="cart.items.length"
						class="notification notification--material position-absolute"
						style="top: 5px"
					>
						{{ cart.items.length }}
					</span>
				</v-ons-toolbar-button>
				<v-ons-toolbar-button
					icon="md-more-vert"
					@click="showPopover($event)"
				/>
			</div>
		</v-ons-toolbar>
		<item-popover />
		<v-ons-popover
			:visible.sync="popoverVisible"
			:target="popoverTarget"
			cancelable
		>
			<v-ons-list>
				<v-ons-list-item
					tappable
					@click="toggleViews"
				>
					<div class="center">
						<p class="m-0">
							Toggle View
						</p>
					</div>
				</v-ons-list-item>
				<v-ons-list-item
					tappable
					@click="addItem"
				>
					<div class="center">
						<p class="m-0">
							Add Item
						</p>
					</div>
				</v-ons-list-item>
				<v-ons-list-item
					v-if="employeePolicy.isAdmin || (employeePolicy.sell && employeePolicy.sell.includes('custom sale'))"
					tappable
					@click="customSale"
				>
					<div class="center">
						<p class="m-0">
							Custom Sale
						</p>
					</div>
				</v-ons-list-item>
				<v-ons-list-item
					:disabled="priceCategories.length == 0 || cart.items.length > 0"
					tappable
					@click="() => {
						popoverVisible = false
						showPriceCategories = true
					}"
				>
					<div class="center">
						<p
							class="m-0"
							:class="{
								'text-muted': cart.items.length > 0
							}"
						>
							Set Price Category
						</p>
					</div>
				</v-ons-list-item>
			</v-ons-list>
		</v-ons-popover>
		<v-ons-action-sheet
			v-if="priceCategories.length > 1"
			:visible.sync="showPriceCategories"
			cancelable
			title="Price Categories"
		>
			<ons-list>
				<ons-list-item
					v-for="(priceCategory, index) in priceCategories"
					:key="index"
					tappable
				>
					<label class="left">
						<ons-radio
							name="selected-price-category"
							:input-id="`price-category-${index}`"
							:value="JSON.stringify(priceCategory)"
							:checked="selectedPriceCategory.id === priceCategory.id"
							@change="setSelectedPriceCategory"
						/>
					</label>
					<label
						:for="`price-category-${index}`"
						class="center"
					>
						{{ priceCategory.name }}
					</label>
				</ons-list-item>
			</ons-list>
		</v-ons-action-sheet>
		<modifiers v-if="selectedVariation" />
		<v-ons-tabbar id="items-tab">
			<template slot="pages">
				<transition
					name="fade"
					mode="out-in"
				>
					<component
						:is="currentTab"
						v-bind="currentTab.onsNavigatorProps"
						shown
					/>
				</transition>
			</template>
			<v-ons-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:label="tab.label"
				:icon="tab.icon"
				:active="currentTab.onsNavigatorProps.type === tab.props.type"
				@click.prevent="showTab(tab)"
			/>
		</v-ons-tabbar>
		<div style="display: flex;align-items: center;height: 100%; width:100%; justify-content:center;">
			<v-ons-progress-circular
				indeterminate
			/>
		</div>
	</v-ons-page>
</template>

<script>
	import Cart from '~/components/cart'
	import Items from './items'
	import CustomSale from './custom-sale'
	import EditItem from './edit-item.vue'

	export default {
		name: 'Sell',
		props: {
			openMenu: {
				type: Function,
				required: true
			}
		},
		data() {
			return {
				popoverVisible: false,
				popoverTarget: null,
				tabs: [
					{
						label: 'All Items',
						icon: 'fa-infinity',
						page: Items,
						props: {
							type: 'ALL'
						}
					},
					{
						label: 'Favorites',
						icon: 'fa-heart',
						page: Items,
						props: {
							type: 'FAVORITES'
						}
					},
					{
						label: 'Top Items',
						icon: 'fa-star',
						page: Items,
						props: {
							type: 'TOP'
						}
					}
				],
				currentTab: {
					extends: Items,
					onsNavigatorProps: {
						type: 'ALL'
					}
				},
				priceCategories: [{
					id: null,
					name: 'Default'
				}],
				showPriceCategories: false,
				showInvalidTimeModal: false,
				backendDomain: process.env.BACKEND_DOMAIN
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			cart() {
				return this.$store.state.cart
			},
			employee() {
				return this.$store.state.employee
			},
			employeePolicy() {
				return this.$store.getters.employeePolicy
			},
			selectedVariation() {
				return this.$store.state.selectedVariation
			},
			gridView(){
				return this.$store.state.toggleViews;
			},
			selectedPriceCategory: {
				get() {
					return this.$store.state.selectedPriceCategory
				},
				set(priceCategory) {
					this.$store.commit('setState', {
									key: 'selectedPriceCategory',
									value: priceCategory
								})

					if (priceCategory.custom_attributes && priceCategory.custom_attributes.labels &&
					priceCategory.custom_attributes.labels.length) {
						const labels = {}

						priceCategory.custom_attributes.labels.forEach(label => {
							labels[
								label.name.toLowerCase().split(' ').join('-')
							] = this.additionalInfo[
								label.name.toLowerCase().split(' ').join('-')
							] || ''
						})

						this.additionalInfo = labels
					} else {
						this.additionalInfo = {}
					}
				}
			},
			additionalInfo: {
				get() {
					return this.$store.state.additionalInfo
				},
				set(additionalInfo) {
					this.$store.commit('setState', {
						key: 'additionalInfo',
						value: additionalInfo
					})

				}
			},
			newOnlineOrdersCount: {
				get() {
					return this.$store.state.newOnlineOrdersCount
				},
				set(count) {
					this.$store.commit('setState', {
						key: 'newOnlineOrdersCount',
						value: count,
						save: true
					})

				}
			},
			showCustomSale: {
				get() {
					return this.$store.state.showCustomSale
				},
				set(data) {
					this.$store.commit('setShowCustomSale', data)
				}
			}
		},
		beforeMount() {
			this.getPriceCategories()
			const discounts = this.$bridge.getDiscounts(this.deviceId, this.locationId, '')

			this.$store.commit('setState', {
					key: 'discounts',
					value: typeof discounts === 'string' ? JSON.parse(discounts) : discounts
			})
		},
		methods: {
			getPriceCategories() {
				const priceCategories = this.$bridge.getPriceCategories(
					this.deviceId, JSON.stringify({
						config: 'global'
					})
				)

				this.priceCategories = this.priceCategories.concat(
					(typeof priceCategories === 'string' ? JSON.parse(priceCategories) : priceCategories)
				)
			},
			showPopover($event) {
				this.popoverVisible = true
				this.popoverTarget = $event
			},
			toggleViews(){
				this.popoverVisible = false
				this.$store.commit('setState', {
					key: 'toggleViews',
					value: !(this.gridView),
					save: true
				})
			},
			setSelectedPriceCategory($event) {
				this.selectedPriceCategory = JSON.parse($event.currentTarget.value)
				setTimeout(() => {
					this.showPriceCategories = false
				}, 200)
			},
			showCart() {
				this.$emit('push', {
					extends: Cart,
					onsNavigatorProps: {
						source: 'sell'
					}
				})
			},
			showTab(tab) {
				if (this.currentTab.onsNavigatorProps.type === tab.props.type)
					this.scrollTop(document.querySelector(`#${tab.props.type}-ITEMS .page__content`))
				else {
					this.currentTab = {
						extends: tab.page,
						onsNavigatorProps: tab.props
					}
				}
			},
			scanItem(){
				this.$bridge.scanItem()
			},
			addItem(){
				this.popoverVisible = false
				this.pageStack.push({
					extends: EditItem,
					onsNavigatorProps: {
						title: "Add Item",
						item: {}
					}
				})
			},
			customSale(){
				this.popoverVisible = false
				this.pageStack.push({
					extends: CustomSale
				})
			},
			search(){
				this.$store.state.searchBarVisibility = !(this.$store.state.searchBarVisibility)
			}
		}
	}
</script>

<style lang="scss">
	#items-tab {
		.tabbar__border {
			display: none !important;
		}

		.tabbar__item.active {
			border-bottom: 1.5px solid #FF8C00;
		}
	}
</style>
