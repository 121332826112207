import $moment from 'moment'

const getters = {
	employeePolicy(state) {
		let policy = {
			isAdmin: state.employee && state.employee.roles[0] ? state.employee.roles[0].isAdmin : false
		}

		if (state.employee &&  state.employee.roles[0] && state.employee.roles[0].policy &&
		state.employee.roles[0].policy.appPolicy) {
			policy = Object.assign({}, policy, state.employee.roles[0].policy.appPolicy)
		}

		return policy
	},
	validDiscounts(state) {
		return state.discounts.filter(discount => {
			return (
				(!discount.valid_from && !discount.expires_on) || (
					$moment().isBetween(discount.valid_from, discount.expires_on)
				)
			) && (!discount.custom_attributes.days || discount.custom_attributes.days.includes(
				$moment().format('dddd').toLowerCase()
			))
		})
	},
	orderDiscounts(state, getters) {
		return getters.validDiscounts.filter(discount => {
			return discount.apply_discount_sub_total && !discount.is_automatic &&
				discount.discount_items.length === 0
		})
	},
	itemDiscounts: (state, getters) => () => {
		return state.subscription.is_trial || state.merchant.features.advance_discount ?
			getters.validDiscounts.filter(discount => {
				return !discount.apply_discount_sub_total && discount.discount_items.length > 0
			}) : []
	},
	autoOrderDiscount: (state, getters) => (type, value) => {
		return state.subscription.is_trial || state.merchant.features.advance_discount ?
			getters.validDiscounts.filter(discount => {
				return discount.buy_condition_type === type && discount.buy_condition_value <= value &&
					discount.apply_discount_sub_total && discount.is_automatic
			}).sort((a, b) => b.buy_condition_value - a.buy_condition_value)[0] : null
	}
}

export default getters
