<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Tables
			</div>
		</v-ons-toolbar>
		<section
			class="position-relative h-100"
			:class="{
				'bg-white': tables.length === 0
			}"
		>
			<div
				class="position-fixed p-2"
				:style="{
					left: 0,
					right: 0,
					backgroundColor: '#eee',
					zIndex: 1
				}"
			>
				<v-ons-search-input
					id="customer-search"
					ref="searchTerm"
					placeholder="Search by table"
					class="w-100"
					@keyup="filterTables($event.currentTarget.value.trim())"
				/>
			</div>
			<section
				v-if="tables.length"
				style="padding-top: 64px"
			>
				<v-ons-list-item
					v-for="(table, index) in tables"
					:key="index"
					class="bg-white"
					tappable
				>
					<label class="left">
						<ons-checkbox
							:input-id="`table-${index}`"
							:value="JSON.stringify(table)"
							name="selected-table"
							:checked="selectedTablesList.findIndex(selectedTable => selectedTable.id === table.id) !== -1"
							@change="setSelectedTableList(table)"
						/>
					</label>
					<label
						:for="`table-${index}`"
						class="center"
					>
						{{ table.name }} ({{ table.floor.name }}) {{ table.seat_count }} Seats
					</label>
				</v-ons-list-item>
			</section>
		</section>
	</v-ons-page>
</template>
<script>
export default {
	name: "TablesList",
	props: {
		tablesSelected: {
			type: Array,
			default() {
				return []
			}
		}
	},
  data() {
    return {
      tables: [],
      temporaryTablesList: [],
			selectedTablesList: []
    }
  },
  computed: {
		pageStack: {
			get() {
				return this.$store.state.pageStack
			},
			set(pageStack) {
				this.$store.commit('setPageStack', pageStack)
			}
		},
		selectedTable() {
			return this.$store.state.selectedTable
		},
    deviceId() {
      return this.$store.state.deviceId
    },
    locationId() {
      return this.$store.state.locationId
    }
  },
	mounted: function () {
		this.selectedTablesList = this.tablesSelected
	},
  beforeMount() {
		this.getTables()
  },
	beforeDestroy(){
		this.$root.$emit('selectedTables', this.selectedTablesList)
	},
  methods: {
    filterTables(searchTerm){
      if (searchTerm) {
        const tables = this.$bridge.getTables(
          this.deviceId,
          this.locationId,
          JSON.stringify({ search_term: searchTerm })
        )
        this.tables = typeof tables === 'string' ? JSON.parse(tables) : tables
      } else {
        this.tables = this.temporaryTablesList
      }
    },
    getTables() {
			if(this.selectedTable){
					this.selectedTablesList = this.selectedTable
			}
      const tables = this.$bridge.getTables(
        this.deviceId, this.locationId, JSON.stringify({
          floor_id: this.selectedFloor ? this.selectedFloor.id : 0
        })
      )

      this.tables = typeof tables === 'string' ? JSON.parse(tables) : tables
      this.temporaryTablesList = this.tables
    },
		findTableSelected(table){
			for (var i = 1; i < this.selectedTablesList.length; i++) {
				if(this.selectedTablesList[i] == table.id){
					return true;
				} else {
					return false;
				}
			}
		},
		setSelectedTableList(table){
			for (var i = 0; i < this.selectedTablesList.length; i++) {
				if(this.selectedTablesList[i] && table.id === this.selectedTablesList[i].id){
					return this.selectedTablesList.splice(i, 1)
				}
			}
			this.selectedTablesList.push(table)
		}
  }
}
</script>
