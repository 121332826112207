const state = () => ({
	appVersion: process.env.VERSION,
	pageStack: [],
	syncModels: {
		merchantDetails:{
			name: 'Merchant Details',
			icon: 'fa-cog',
			sync: true
		},
		employees: {
			name: 'Employees',
			modelName: 'Employee',
			icon: 'fa-user-tie',
			percent: null
		},
		devices: {
			name: 'Devices',
			modelName: 'Device',
			icon: 'fa-mobile',
			percent: null
		},
		floors: {
			name: 'Floors',
			modelName: 'Floor',
			icon: 'fa-building',
			percent: null
		},
		floorTables: {
			name: 'Tables',
			modelName: 'Table',
			icon: 'fa-utensils',
			percent: null
		},
		merchantCustomers: {
			name: 'Customers',
			modelName: 'Customer',
			icon: 'fa-users',
			percent: null
		},
		categories: {
			name: 'Categories',
			modelName: 'Category',
			icon: 'fa-sitemap',
			percent: null
		},
		taxes: {
			name: 'Taxes',
			modelName: 'Tax',
			icon: 'fa-file-invoice-dollar',
			percent: null
		},
		discounts: {
			name: 'Discounts',
			modelName: 'Discount',
			icon: 'fa-percent',
			percent: null
		},
		merchantPriceCategories: {
			name: 'Price Categories',
			modelName: 'PriceCategory',
			icon: 'fa-tags',
			percent: null
		},
		items: {
			name: 'Items',
			modelName: 'Item',
			icon: 'fa-list',
			percent: null
		},
		itemVariationGroups: {
			name: 'Item Variation Groups',
			modelName: 'ItemVariationGroup',
			icon: 'fa-edit',
			percent: null
		},
		merchantPaymentMethods: {
			name: 'Payment Methods',
			modelName: 'PaymentMethod',
			icon: 'fa-credit-card',
			percent: null
		}
	},
	deviceId: null,
	locationId: null,
	locationName: null,
	isLoggedIn: false,
	loginToken: null,
	merchant: {
		features: {}
	},
	location: {},
	subscription: null,
	settings: {
		sale: {},
		general: {},
		receipt: {},
		barcode: {}
	},
	employees: [],
	employee: null,
	cashDrawer: {
		show: false,
		close: true,
		type: ''
	},
	cashDrawerShift: null,
	selectedCategoryId: null,
	selectedPriceCategory: {
		id: null,
		name: 'Default'
	},
	selectedItem: null,
	selectedVariation: null,
	itemPopover: {
		show: false,
		target: null
	},
	showModifiers: false,
	favorites: [],
	cart: {
		items: [],
		price: {
			subTotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: [],
			discount: 0,
			discounts: [],
			total:0
		},
		priceCategory: null
	},
	showConfirmWOPrint: true,
	printerSettings: {
		paperSize: 2,
		cutPaperAfterPrint: true,
		openCashboxAfterPrint: false,
		printShiftSummaryAfterClockout: true,
		printShiftSummaryCategoryItems: false,
		printShiftSummaryDenominations: false,
		reprintKot: false,
		scaleFactor: 2
	},
	kots: [],
	selectedTable: null,
	selectedOrder: null,
	lastSelectedTableId: null,
	denominations: [],
	orderReceipt: {
		print: false,
		type: '',
		data: null
	},
	shiftReceipt: {
		print: false,
		source: ''
	},
	discounts: [],
	showDiscount: false,
	showOrderDiscounts: false,
	selectedDiscount: null,
	additionalInfo: {},
	ezetap: {
		enabled: false,
		showModal: false,
		status: 'processing',
		message: 'Sending request to device...',
		credentials: {
			appKey: '',
			deviceId: '',
			username: '',
			password: ''
		},
		origP2pRequestId: '',
		externalRefNumber: ''
	},
	mSwipe:{
		enabled: false,
		credentials:{
			username:'',
			password:''
		}
	},
	onlineSelectedState: null,
	onlineSelectedChannel: null,
	selectedOnlineOrder: null,
	newOnlineOrdersCount: 0,
	onHold: [],
	isWaiterAppRunning: false,
	appMode: '',
	isWeighingScaleEnabled: false,
	isEzSwype: false,
	showVariablePriceModel: false,
	showCustomSale: false,
	selectedTables: [],
	onlineOrder:{
		getOnlineOrders : false
	},
	searchBarVisibility:false,
	toggleViews:false,
	loopNotificationSound:false
})

export default state
