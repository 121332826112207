<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Checkout
			</div>
			<div class="right">
				<v-ons-toolbar-button
					v-if="merchant.businessType === 'restaurant' && source === 'sell'"
					icon="fa-utensils"
					@click="assignTable"
				>
					<span
						v-if="merchant.businessType === 'restaurant' && selectedTables && selectedTables.length > 0"
						class="notification-dot notification notification--material position-absolute"
					>
						{{ ' ' }}
					</span>
				</v-ons-toolbar-button>
				<v-ons-toolbar-button
					icon="md-account-add"
					@click="addCustomer"
				>
					<span
						v-if="customer.id"
						class="notification-dot notification notification--material position-absolute"
					>
						{{ ' ' }}
					</span>
				</v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<div class="content">
			<v-ons-list class="pb-5">
				<v-ons-list-header v-if="customer.id">
					<v-ons-row>
						<v-ons-col vertical-align="center">
							Selected Customer
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<v-ons-toolbar-button
								icon="md-close-circle"
								class="float-right p-0 m-0"
								@click="resetCustomer"
							/>
						</v-ons-col>
					</v-ons-row>
				</v-ons-list-header>
				<v-ons-list-item v-if="customer.id">
					<v-ons-row class="center pt-0">
						<v-ons-col>
							<p class="text-capitalize m-0">
								{{ customer.first_name + ' ' + (customer.last_name || '') }}
							</p>
							<small>{{ customer.phone | mask('mobile') }}</small>
						</v-ons-col>
						<v-ons-col class="text-center">
							<p class="m-0">
								Limit
							</p>
							<small>{{ customer.credit_limit | currency({
								symbol: merchant.currencySymbol
							}) }}</small>
						</v-ons-col>
						<v-ons-col class="text-center">
							<p class="m-0">
								Outstanding
							</p>
							<small>{{ (customer.credit && !customer.debit ? customer.credit : (customer.credit - customer.debit)) | currency({
								symbol: merchant.currencySymbol
							}) }}</small>
						</v-ons-col>
					</v-ons-row>
				</v-ons-list-item>
				<v-ons-list-header v-if="selectedTables && selectedTables.length">
					<v-ons-row>
						<v-ons-col vertical-align="center">
							Selected Table
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<v-ons-toolbar-button
								icon="md-close-circle"
								class="float-right p-0"
								@click="resetSelectedTable"
							/>
						</v-ons-col>
					</v-ons-row>
				</v-ons-list-header>
				<v-ons-list v-if="selectedTables && selectedTables.length">
					<v-ons-list-item
						v-for="(tableItem, index) in selectedTables"
						:key="index"
						class="center"
					>
						<v-ons-col
							width="70%"
							class="m-0"
						>
							{{ tableItem.name }} ({{ tableItem.floor.name }}) {{ tableItem.seat_count }} Seats
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<v-ons-toolbar-button
								icon="md-close-circle"
								class="float-right p-0"
								style="margin-right: 13px;"
								@click="resetSelectedTableItem(index)"
							/>
						</v-ons-col>
					</v-ons-list-item>
				</v-ons-list>
				<div
					v-if="selectedTables == null || selectedTables.length === 0"
				>
					<v-ons-list-header>
						Payment Methods
					</v-ons-list-header>
					<v-ons-list-item
						v-for="(paymentMethod, index) in paymentMethods"
						:key="index"
						:expanded="paymentMethodSlug === paymentMethod.slug"
						expandable
						@click="setPaymentMethod(paymentMethod.slug, $event)"
					>
						<div class="center">
							{{ paymentMethod.name }}
						</div>
						<div class="expandable-content">
							<ValidationObserver ref="validator">
								<ValidationProvider
									v-if="paymentMethod.slug === 'cash'"
									ref="cashValidator"
									v-slot="{ errors }"
									name="cash-amount"
									:rules="{
										required: true,
										min_value: totalPrice.toFixed(2)
									}"
								>
									<v-ons-input
										v-model="cashAmount"
										type="number"
										step=".01"
										name="cash-amount"
										size="lg"
										class="w-100"
										placeholder="Tender amount"
										float
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
								<div v-else-if="paymentMethod.slug === 'other'">
									<h4 class="text-center">
										Tender Amount: {{ totalPrice | currency({
											symbol: merchant.currencySymbol
										}) }}
									</h4>
									<div class="card-deck mb-6">
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'cheque'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Cheque
												</Small>
											</div>
										</div>
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'gift_card'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Gift Card
												</Small>
											</div>
										</div>
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'mobile_wallet'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Mobile Wallet
												</Small>
											</div>
										</div>
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'mobile'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Mobile Payment
												</Small>
											</div>
										</div>
									</div>
									<div class="form-group">
										<v-ons-input
											v-model="otherPaymentNote"
											size="lg"
											class="w-100 mt-2"
											placeholder="Optional Notes"
											float
										/>
									</div>
								</div>
								<ValidationProvider
									v-else-if="paymentMethod.slug === 'card' && (!isEzSwype && !ezetap.enabled && !mSwipe.enabled)"
									ref="cardValidator"
									v-slot="{ errors }"
									name="card-number"
									:rules="{
										required: true,
										length : 4,
										numeric: true
									}"
								>
									<v-ons-input
										v-model="cardNumber"
										maxlength="4"
										type="number"
										step="1"
										name="card-number"
										class="w-100"
										placeholder="Last 4 digits on card"
										float
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
								<div
									v-else
									class="text-center"
								>
									<h4>
										Tender Amount: {{ totalPrice | currency({
											symbol: merchant.currencySymbol
										}) }}
									</h4>
								</div>
							</ValidationObserver>
						</div>
					</v-ons-list-item>
				</div>
			</v-ons-list>
			<v-ons-row
				style="bottom: 0; z-index: 5;height: 40px;line-height: 40px;"
				class="position-fixed"
			>
				<v-ons-col
					v-if="showConfirmWOPrint || (selectedTables != null && selectedTables.length)"
					style="left: 0;right: 0;"
					:class="(selectedTables == null || selectedTables.length < 1)? 'mr-1': ''"
				>
					<v-ons-button
						class="rounded-0"
						modifier="large"
						@click="preProcessOrder(false)"
					>
						{{ (selectedTables != null && selectedTables.length) ? 'Send To KOT' : 'Confirm W/O Print' }}
					</v-ons-button>
				</v-ons-col>
				<v-ons-col
					v-if="selectedTables == null || selectedTables.length === 0"
					style="left: 0;right: 0;"
				>
					<v-ons-button
						class="rounded-0"
						modifier="large"
						@click="preProcessOrder(true)"
					>
						{{ 'Confirm' }}
					</v-ons-button>
				</v-ons-col>
			</v-ons-row>
		</div>

		<table-orders-action
			v-if="showTableOrdersModal && tableOrders.length > 0"
			:orders="tableOrders"
			@update:showTableOrdersModal="showTableOrdersModal = $event"
			@update:processOrder="processTableOrder($event)"
		/>
	</v-ons-page>
</template>

<script>
	import Sell from '~/components/sell'
	import AddCustomer from '~/components/add-customer'
	import OrderAssignTable from '~/components/order-assign-table'
	import { ValidationObserver, ValidationProvider } from "vee-validate"

	const initCustomer = {
		phone: '',
		address: {
			residence: {}
		},
		custom_attributes: {}
	}

	export default {
		name: 'Checkout',
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			source: {
				type: String,
				required: true
			},
			orderProp: {
				type: Object,
				default() {
					return {}
				}
			}
		},
		data() {
			return {
				order: Object.assign({}, this.orderProp),
				totalPrice: 0,
				cashAmount: null,
				cardNumber: '',
				customer: initCustomer,
				table: {},
				paymentMethods: [],
				paymentMethodSlug: 'cash',
				otherPaymentType: null,
				otherPaymentNote: '',
				availableTables: [],
				selectedTables: [],
				tableOrders: [],
				showTableOrdersModal: false,
				processingOrder: false,
				test: false,
				print: true,
				customAttributes: {}
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			appVersion() {
				return this.$store.state.appVersion
			},
			appVersionNumber() {
				return parseInt(this.appVersion.replace(/\./g, ''))
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			merchant() {
				return this.$store.state.merchant
			},
			employee() {
				return this.$store.state.employee
			},
			checkout() {
				return this.$store.state.checkout
			},
			selectedPriceCategory() {
				return this.$store.state.selectedPriceCategory
			},
			cart() {
				return this.$store.state.cart
			},
			customers() {
				return this.$store.state.customers
			},
			cashDrawerShift() {
				return this.$store.state.cashDrawerShift
			},
			kots() {
				return this.$store.state.kots
			},
			selectedTable() {
				return this.$store.state.selectedTable
			},
			selectedOrder: {
				get() {
					return this.$store.state.selectedOrder
				},
				set(order) {
					this.$store.commit('setState', {
						key: 'selectedOrder',
						value: order
					})
				}
			},
			keyboardShortcuts() {
				return this.$store.state.keyboardShortcuts
			},
			settings() {
				return this.$store.state.settings
			},
			selectedDiscount: {
				get() {
					return this.$store.state.selectedDiscount
				},
				set(discount) {
					this.$store.commit('setState', {
						key: 'selectedDiscount',
						value: discount
					})
				}
			},
			additionalInfo: {
				get() {
					return this.$store.state.additionalInfo
				},
				set(additionalInfo) {
					this.$store.commit('setState', {
							key: 'additionalInfo',
							value: additionalInfo
						})
				}
			},
			showConfirmWOPrint() {
				return this.$store.state.showConfirmWOPrint
			},
			isEzSwype() {
				return this.$store.state.isEzSwype
			},
			ezetap() {
				return this.$store.state.ezetap
			},
			mSwipe(){
				return this.$store.state.mSwipe
			},
			subscription() {
				return this.$store.state.subscription
			}
		},
		beforeMount() {
			this.initEventBus()
			this.$store.dispatch('ezetapValidate')
			this.$store.dispatch('mSwipeValidate')
			const paymentMethods = this.$bridge.getPaymentMethods(this.deviceId, '')
			this.paymentMethods = typeof paymentMethods === 'string' ?
				JSON.parse(paymentMethods) : paymentMethods
			this.totalPrice = this.source === 'table' ?
				this.order.total_price : this.cart.price.total

			this.cashAmount = this.totalPrice.toFixed(2)

			if (this.selectedTable)
				this.selectedTables.push(this.selectedTable)

			if (this.selectedOrder && this.selectedOrder.customer)
				this.customer = this.selectedOrder.customer

			if (this.order.customer && this.order.customer.id)
				this.customer = this.order.customer
		},
		mounted(){
			this.test = true
			window.cardPaymentSuccess = this.cardPaymentSuccess
			window.cardPaymentFailure = this.cardPaymentFailure
		},
		destroyed() {
			this.$store.commit('setState', {
				key: 'selectedTable',
				value: null
			})
		},
		methods: {
			initEventBus() {
				this.$root.$on('updateCustomer', $event => {
					this.customer = $event
				})
				this.$root.$on('selectedTables', $event => {
					this.selectedTables = $event
				})
			},
			addCustomer() {
				this.$emit('push', {
					extends: AddCustomer,
					onsNavigatorProps: {
						customerProp: this.customer
					}
				})
			},
			resetCustomer() {
				this.customer = initCustomer
			},
			setPaymentMethod(paymentMethodSlug, $event) {
				if (!$event.currentTarget.classList.contains('expanded')){
						this.paymentMethodSlug = paymentMethodSlug
				}
			},
			generateReceiptCode() {
				const date = new Date()
				const lastOrderDay = +this.$bridge.getLocalStorage('lastOrderDay')
				let orderCount = +this.$bridge.getLocalStorage('orderCount')

				if (lastOrderDay !== date.getDate()) {
					orderCount = 1
				} else {
					orderCount += 1
				}

				return this.$moment().format('YYMMDD') + orderCount.toString().padStart(4, '0')
			},
			preProcessOrder(print) {
				const tables = this.order.tables || this.selectedTables
				if (tables.length) {
					const tableOrders = this.$bridge.getOrders(this.deviceId, JSON.stringify({
						table_id: tables.map(table => table.id),
						status: 'pending'
					}))

					this.tableOrders = (
						typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders
					).data
					if (this.source === 'table')
						return this.validateOrder(print)
					else if (this.source === 'sell' && this.tableOrders.length) {
						if (!this.selectedOrder){
							return this.showTableOrdersModal = true
							}
						else {
							this.order = this.tableOrders
								.find(order => order.id === this.selectedOrder.id) || {}
						}
					}

					this.processOrder(false)
				} else {
					this.validateOrder(print)
				}
			},
			processTableOrder($event) {
				if (this.tableOrders[$event]) {
					this.order = { ...this.tableOrders[$event] }
					this.addOrderItemsToCart(this.order, this.cart.items.reverse())
					if (this.order.customer){
						this.customer = this.order.customer
					}
				}
				this.showTableOrdersModal = false
				this.processOrder(false)
			},
			async validateOrder(print) {
				this.processingOrder = true
				switch (this.paymentMethodSlug) {
					case 'cash':
						if ((await this.$refs.cashValidator[0].validate()).valid){
							this.processOrder(print)
						} else{
							this.processingOrder = false
						}
						break
					case 'card':
						if(this.isEzSwype || this.ezetap.enabled|| this.mSwipe.enabled){
							this.print = print
							this.$bridge.payCard(JSON.stringify({"amount": this.totalPrice}))
						} else {
							if ((await this.$refs.cardValidator[0].validate()).valid){
									this.processOrder(print)
							} else{
								this.processingOrder = false
							}
						}
						break
					case 'credit':
						if (this.customer.phone) {
							if (((this.customer.credit && !this.customer.debit ?
								this.customer.credit : (this.customer.credit - this.customer.debit)
							) + this.totalPrice) > this.customer.credit_limit) {
								this.$ons.notification.confirm('Are you sure you want to continue?', {
									title: 'Credit limt exceeded!'
								}).then(response => {
									if (response)
										this.processOrder(print)
									else
										this.processingOrder = false
								})
							} else {
								this.processOrder(print)
							}
						} else {
							this.$ons.notification.alert('Please add customer to continue', {
								title: 'Customer not added'
							})

							this.processingOrder = false
						}
						break
					case 'other':
						this.processOrder(print)
						break
					default:
						this.processOrder(print)
						break
				}
			},
			processOrder(print) {
				const date = new Date()
				const cart = JSON.parse(JSON.stringify(this.cart))
				let items = []
				let kotItems = []

				this.$store.commit('resetCart')

				cart.items.forEach((itemVariation, index) => {
					const totalDiscount = itemVariation.discountedAmount + itemVariation.discountedTax
					const item = {
						item_id: itemVariation.item_id,
						category_id: itemVariation.category_id,
						item_variation_id: itemVariation.id,
						item_inventory_id: itemVariation.inventory_id,
						kot_device_id: itemVariation.kot_device_id,
						item_code: `${this.deviceId}${itemVariation.id}${date.valueOf()}${index}`,
						item_name: itemVariation.item_name,
						item_variation_name: itemVariation.name,
						alternate_name: itemVariation.alternate_name,
						barcode: itemVariation.barcode,
						hsn: itemVariation.hsn,
						itemization_type: itemVariation.itemization_type,
						unit_measure_type: itemVariation.unit_measure_type,
						mrp: itemVariation.mrp,
						single_quantity_amount: itemVariation.price,
						quantity: Number(itemVariation.quantity),
						groups: itemVariation.groups,
						notes: '',
						sub_total: itemVariation.subTotal,
						total: (itemVariation.subTotal + itemVariation.taxAmount) - totalDiscount,
						gross_sales: itemVariation.subTotal,
						net_sales: itemVariation.subTotal - itemVariation.discountedAmount,
						tax: itemVariation.taxAmount,
						tax_type: itemVariation.taxType,
						tax_details: itemVariation.taxes,
						discount: totalDiscount,
						discounted_amount: itemVariation.discountedAmount,
						discounted_tax: itemVariation.discountedTax,
						discount_details: itemVariation.discounts

					}

					items.unshift(item)

					if (['restaurant', 'qsr'].includes(this.merchant.businessType)) {
						const orderItem = this.order.id ? this.order.items.find(i => {
							return i.variation_id === itemVariation.id && i.price == itemVariation.price
						}) : null

						if (!orderItem || (orderItem && orderItem.quantity !== item.quantity)) {
							if (itemVariation.type === 'combo') {
								kotItems = [...(item.groups.filter(g => g.type === 'combo')), ...kotItems]
							} else {
								kotItems.unshift({
									kot_device_id: item.kot_device_id,
									item_variation_name: item.item_variation_name,
									alternate_name: item.alternate_name,
									unit_measure_type: item.unit_measure_type,
									quantity: orderItem ? Math.abs(
										orderItem.quantity - itemVariation.quantity
									) : itemVariation.quantity,
									modifiers: item.groups.filter(g => g.type === 'modifier')
								})
							}
						}
					}
				})

				const total = this.totalPrice
				const balanceReturned = this.paymentMethodSlug === 'cash' ? parseFloat((this.cashAmount - total).toFixed(2)) : 0.00
				const generatedReceiptCode = this.order.receipt_code ? this.order.receipt_code
					.substr(-10) : this.generateReceiptCode()
				const receiptCode = `${this.employee.id}${this.deviceId}${date.valueOf()}${
					generatedReceiptCode
				}`
				this.customAttributes = {
					shift_id: this.employee.shiftId
				}
				const labels = Object.keys(this.additionalInfo)

				if (this.paymentMethodSlug === 'other' && this.otherPaymentType)
					this.customAttributes.other_payment_method = this.otherPaymentType

				if (labels.length) {
					this.customAttributes.labels = labels.map(i => {
						return {
							label: i,
							value: this.additionalInfo[i]
						}
					})
				}

				const tables = this.order.id && this.order.tables.length ? this.order.tables : this.selectedTables
				let tableOrders = this.$bridge.getOrders(this.deviceId, JSON.stringify({
					table_id: tables.map(t => t.id),
					status: ['pending', 'billed']
				}))

				tableOrders = (typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders).data

				const order = {
					id: this.order.id || date.valueOf(),
					device_id: this.deviceId,
					location_id: this.locationId,
					employee_id: this.employee.id,
					shift_id: this.employee.shiftId,
					payment_method_id: this.selectedTables.length === 0 ? this.paymentMethods
						.find(paymentMethod => paymentMethod.slug === this.paymentMethodSlug)
						.payment_method_id : null,
					created_by: this.order.created_by ? this.order.created_by.id : null,
					employee_shift_code: this.employee.shiftCode,
					customer_id: this.customer.phone ? parseInt(this.customer.phone) : null,
					tables: tables.map(table => {
						const orders = tableOrders.filter(o => {
							return o.tables.findIndex(t => t.id === table.id) !== -1
						})

						return {
							...table,
							custom_attributes: this.selectedTables.length ?
								JSON.stringify(table.custom_attributes) : '{}',
							is_occupied: this.source === 'sell' || orders.length > 1,
							updated_at: date
						}
					}),
					merchant_price_category_id: this.order.price_category ?
						this.order.price_category.id : this.selectedPriceCategory.id,
					price_category: this.order.price_category ?
						this.order.price_category : this.selectedPriceCategory,
					status: this.selectedTables.length ? 'pending' : 'completed',
					receipt_code: receiptCode,
					sub_total: cart.price.subTotal,
					inclusive_tax: cart.price.inclusiveTaxTotal,
					additive_tax: cart.price.exclusiveTaxTotal,
					total_tax: cart.price.tax,
					taxes: cart.price.taxes,
					total_price: cart.price.total,
					total_discount: cart.price.discount,
					total_discounted_amount: cart.price.discountedAmount,
					total_discounted_tax: cart.price.discountedTax,
					discounts: this.selectedDiscount ? [this.selectedDiscount] : [],
					payment_method_slug: this.selectedTables.length === 0 ? this.paymentMethodSlug : null,
					card_details: this.paymentMethodSlug === 'card' && this.cardNumber ? {
						number: this.cardNumber
					} : {},
					payment_note: this.paymentMethodSlug === 'other' ? this.otherPaymentNote : '',
					amount_balance_returned: balanceReturned,
					total_amount: this.selectedTables.length === 0 ? (
						this.paymentMethodSlug === 'cash' ? parseFloat(this.cashAmount) : total
					) : 0,
					round_off_amount: cart.price.roundOff,
					custom_attributes: this.customAttributes,
					items: items,
					customers: this.customer.phone ? [{
						first_name: this.customer.first_name,
						last_name: this.customer.last_name,
						customer_code: this.customer.code,
						email: this.customer.email,
						phone: this.customer.phone,
						alternative_phone: this.customer.alternative_phone,
						amount: total,
						credit_code: receiptCode,
						credit_limit: this.customer.credit_limit,
						currency_code: this.merchant.currencyCode,
						calling_code: this.merchant.businessPhone.calling_code,
						address: this.customer.address,
						custom_attributes: this.customer.custom_attributes
					}] : [],
					kot_history: this.order.id ? this.order.kot_history : [],
					created_at: this.order.created_at || this.$moment.utc(date)
						.format('YYYY-MM-DD HH:mm:ss'),
					updated_at: date
				}

				if (kotItems.length && ['restaurant', 'qsr'].includes(this.merchant.businessType) && this.source === 'sell') {
					const kotDeviceItems = this.groupBy(kotItems, 'kot_device_id')

					order.kot_history.push(Object.assign({}, kotDeviceItems))

					if (this.kots.length && typeof window.printKot === 'function') {
						window.printKot({
							...order,
							items: kotDeviceItems,
							is_running_order: this.order.id ? true : false
						})
					}
				}

				if (print) {
					this.$store.commit('setState', {
						key: 'orderReceipt',
						value: {
							print: true,
							type: 'order',
							data: Object.assign({}, order)
						}
					})
				}
				this.$bridge.put('Order', this.$bridge.getName() === 'ANDROID' ?
					JSON.stringify(order) : order)

				if (this.customer.phone) {
					const customer = Object.assign({}, this.customer, {
						device_id: this.deviceId,
						address: JSON.stringify(this.customer.address),
						credit: this.paymentMethodSlug === 'credit' ? ((this.customer.credit || 0) + total) : (this.customer.credit || 0),
						custom_attributes: JSON.stringify(this.customer.custom_attributes),
						updated_at: date
					})

					this.$bridge.put('Customer', this.$bridge.getName() === 'ANDROID' ?
						JSON.stringify(customer) : customer)
				}

				if (!this.order.id) {
					this.$bridge.put('lastOrderDay', date.getDate())
					this.$bridge.put('orderCount', generatedReceiptCode.substr(-4))
				}

				if (order.status === 'completed') {
					order.tables = order.tables.map(table => table.id)
					delete order.updated_at

					let syncData = {
						id: date.valueOf(),
						model_id: order.id,
						model_name: 'order',
						payload: JSON.stringify({
							model_id: order.id,
							device_id: order.device_id,
							location_id: order.location_id,
							employee_id: order.employee_id,
							orders: [order]
						})
					}
					this.$bridge.put('OrderSync', this.$bridge.getName() === 'ANDROID' ?
						JSON.stringify(syncData) : syncData)

					if (this.paymentMethodSlug === 'cash') {
						const event = {
							id: date.valueOf(),
							device_id: this.deviceId,
							employee_id: this.employee.id,
							employee_shift_id: this.employee.shiftId,
							cash_drawer_shift_id: this.cashDrawerShift.id,
							amount: total,
							cash_via: 'sale',
							type: 'cash_in',
							updated_at: date
						}

						this.$bridge.put('CashDrawerShiftEvent', this.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(event) : event)

						syncData = {
							id: date.valueOf() + 1,
							model_id: event.id,
							model_name: 'cash-drawer-shift-event',
							payload: JSON.stringify({
								model_id: event.id,
								cash_drawer_shift_id: event.cash_drawer_shift_id,
								employee_id: event.employee_id,
								event_type: event.type,
								cash_via: event.cash_via,
								event_money: event.amount,
								shift_event_code: `${this.deviceId}${date.valueOf()}`,
								employee_shift_code: this.employee.shiftCode
							})
						}

						this.$bridge.put('CashDrawerShiftEventSync', this.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(syncData) : syncData)
					}
				}

				this.selectedDiscount = null
				this.additionalInfo = {}
				this.pageStack = [this.pageStack[0]]
				if(this.source === 'table'){
					this.$root.$emit('updateTableOrder', true)
				}
				this.$store.commit('setSelectedVariation', null)
				this.$ons.notification.alert('Order placed successfully!', {
					title: 'Success'
				})
			},
			assignTable(){
				this.$emit('push', {
					extends: OrderAssignTable,
					onsNavigatorProps: {
						tablesSelected: this.selectedTables
					}
				})
			},
			resetSelectedTable(){
				this.selectedTables = []
			},
			resetSelectedTableItem(index){
				this.selectedTables.splice(index, 1)
			},
			cardPaymentSuccess(data){
				this.customAttributes = data
				this.processOrder(this.print)
			},
			cardPaymentFailure(message){
				this.$ons.notification.toast(message, {timeout: 2000})
			}
		}
	}
</script>
