<template>
	<v-ons-alert-dialog
		modifier="rowfooter"
		:visible.sync="cashDrawer.show"
		@posthide="resetValues"
	>
		<span slot="title">Cash Drawer</span>
		<ValidationObserver ref="validator">
			<ValidationProvider
				v-slot="{ errors }"
				name="amount"
				:rules="{
					required: true,
					decimal: 2,
					min_value: ['cash in', 'cash out'].includes(cashDrawer.type) ? .01 : 0
				}"
			>
				<v-ons-input
					v-model="amount"
					type="number"
					step=".01"
					name="amount"
					:placeholder="`Enter ${cashDrawer.type} amount`"
					class="w-100"
					:class="{
						'mb-3': cashDrawer.type !== 'closing'
					}"
					float
				/>
				<small class="text-danger">{{ errors[0] }}</small>
			</ValidationProvider>
		</ValidationObserver>
		<div v-if="cashDrawer.type !== 'closing'">
			<v-ons-input
				v-model="description"
				type="text"
				name="description"
				placeholder="Description"
				class="w-100"
				:class="{
					'mb-3': cashDrawer.type === 'cash out'
				}"
				float
			/>
			<v-ons-select
				v-if="cashDrawer.type === 'cash out'"
				v-model="cashVia"
				class="w-100"
			>
				<option
					v-for="(option, index) in paidForOptions"
					:key="index"
					:value="option.value"
				>
					{{ option.text }}
				</option>
			</v-ons-select>
		</div>
		<template slot="footer">
			<div class="px-2">
				<v-ons-alert-dialog-button
					v-if="cashDrawer.close"
					@click="cancel"
				>
					Cancel
				</v-ons-alert-dialog-button>
				<v-ons-alert-dialog-button @click="processShift">
					Submit
				</v-ons-alert-dialog-button>
			</div>
		</template>
	</v-ons-alert-dialog>
</template>

<script>
	import Passcode from '~/components/passcode'
	import { ValidationObserver, ValidationProvider } from "vee-validate";

	export default {
  	name: "CashDrawer",
		components: {
	    ValidationObserver,
	    ValidationProvider
	  },
		data() {
			return {
				amount: 0,
				description: '',
				cashVia: 'other',
				paidForOptions: [
					{
						text: 'Vendor Purchase',
						value: 'purchase'
					},
					{
						text: 'Employee Salary',
						value: 'salary'
					},
					{
						text: 'Expenses',
						value: 'expense'
					},
					{
						text: 'Other',
						value: 'other'
					}
				],
				cashDrawerShiftEvents: [],
				availableCash: '0.00'
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			merchant() {
				return this.$store.state.merchant
			},
			employee() {
				return this.$store.state.employee
			},
			cashDrawer: {
				get() {
					return this.$store.state.cashDrawer
				},
				set(cashDrawer) {
					this.$store.commit('resetCashDrawer', cashDrawer)
				}
			},
			cashDrawerShift() {
				return this.$store.state.cashDrawerShift
			}
		},
		beforeMount() {
			if (this.cashDrawer.type === 'closing') {
				const cashDrawerShiftEvents = this.$bridge
					.getCashDrawerShiftEvents(this.cashDrawerShift.id)

				this.cashDrawerShiftEvents = typeof cashDrawerShiftEvents === 'string' ?
					JSON.parse(cashDrawerShiftEvents) : cashDrawerShiftEvents

				this.availableCash = (parseFloat(this.cashDrawerShift.starting_cash_amount) +
					this.getEventData('cash_in').sum - this.getEventData('cash_out').sum)
					.toFixed(2)
			}
		},
		methods: {
			cancel() {
				this.$store.commit('resetCashDrawer')
			},
			async processShift() {
				if (await this.$refs.validator.validate()) {
					const type = this.cashDrawer.type
					const date = new Date()
					const code = `${this.deviceId}${date.valueOf()}`
					let shift = this.cashDrawerShift ? this.$bridge.getCashDrawerShifts(
						this.deviceId,
						JSON.stringify({ id: this.cashDrawerShift.id })
					) : null
					shift = typeof shift === 'string' ? JSON.parse(shift) : shift

					if (shift && shift.data.length)
						shift = shift.data[0]

					if (type === 'starting') {
						shift = {
							id: date.valueOf(),
							device_id: this.deviceId,
							employee_id: this.employee.id,
							shift_code: code,
							starting_cash_amount: parseFloat(this.amount),
							description: this.description,
							opened_at: this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
							updated_at: date
						}
					} else if (type === 'closing' && shift) {
						shift.closed_at = this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
						shift.closed_cash_amount = parseFloat(this.amount)
					}

					if (shift && (type === 'starting' || type === 'closing')) {
						this.$store.commit('setState', {
							key: 'cashDrawerShift',
							value: shift,
							save: true
						})
						this.$bridge.setLocalStorage('cashDrawerShift', JSON.stringify(shift))

						if (type === 'starting') {
							this.$bridge.insert(
								'CashDrawerShift',
								this.$bridge.getName() === 'ANDROID' ? JSON.stringify(shift) : shift,
								true
							)

							const syncData = {
								id: date.valueOf(),
								model_id: shift.id,
								model_name: 'cash-drawer-shift',
								payload: JSON.stringify({
									model_id: shift.id,
									device_id: this.deviceId,
									shift_code: shift.shift_code,
									cash_drawer_status: type === 'starting' ? 'open' : 'closed',
									employee_shift_code: this.employee.shiftCode,
									opened_at: shift.opened_at,
									opening_employee_id: this.employee.id,
									starting_cash_money: shift.starting_cash_amount,
									description: shift.description
								})
							}

							this.$bridge.insert(
								'Sync',
								this.$bridge.getName() === 'ANDROID' ?
									JSON.stringify(syncData) : syncData,
								false
							)
						}
					} else if (shift && (type === 'cash in' || type === 'cash out')) {
						const event = {
							id: date.valueOf(),
							device_id: this.deviceId,
							employee_id: this.employee.id,
							employee_shift_id: this.employee.shiftId,
							cash_drawer_shift_id: this.cashDrawerShift.id,
							amount: parseFloat(this.amount),
							cash_via: this.cashVia,
							type: type.replace(' ', '_'),
							description: this.description,
							updated_at: date
						}

						this.$bridge.insert(
							'CashDrawerShiftEvent',
							this.$bridge.getName() === 'ANDROID' ? JSON.stringify(event) : event,
							true
						)

						const syncData = {
							id: date.valueOf(),
							model_id: event.id,
							model_name: 'cash-drawer-shift-event',
							payload: JSON.stringify({
								model_id: event.id,
								cash_drawer_shift_id: event.cash_drawer_shift_id,
								employee_id: event.employee_id,
								event_type: event.type,
								cash_via: event.cash_via,
								event_money: event.amount,
								shift_event_code: code,
								employee_shift_code: this.employee.shiftCode,
								description: this.description
							})
						}

						this.$bridge.insert(
							'Sync', this.$bridge.getName() === 'ANDROID' ? JSON.stringify(syncData) : syncData,
							false
						)

						this.$root.$emit('cashDrawerEvent', {
							shift_id: this.cashDrawerShift.id,
							amount: event.amount,
							cash_via: event.cash_via,
							type: event.type
						})
					}
					this.cashDrawer.show = false
					if (type === 'closing') {
						setTimeout(() => {
							this.pageStack.push({
								extends: Passcode,
								onsNavigatorProps: {
									type: 'clockout'
								}
							})
						}, 100)
					} else {
						this.$ons.notification.alert(type === 'starting' ? 'Cash drawer shift started' : (
							type === 'cash in' ? 'Cash received successfully' : 'Cash paid out successfully'
						), {
							title: 'Success'
						})
					}
				}
			},
			getEventData(type, cashVia) {
				const events = this.cashDrawerShiftEvents.filter(event => {
					return event.type === type && (!cashVia || (cashVia && event.cash_via === cashVia))
				})

				return {
					count: events.length,
					sum: events.length ? events.reduce((sum, event) => sum + event.amount, 0) : 0
				}
			},
			resetValues(){
				this.$store.commit('resetCashDrawer')
				this.$refs.validator.reset()
				this.amount = 0
				this.description = ''
				this.cashVia = 'other'
			}
		}
	}
</script>
