const mutations = {
	setState(state, data) {
		state[data.key] = data.value

		if (data.save === true) {
			this.$bridge.setLocalStorage(
				data.key,
				typeof data.value === 'object' ? JSON.stringify(data.value) : data.value
			)
		} else if (data.save === false) {
			this.$bridge.removeLocalStorage(data.key)
		}
	},
	setPageStack(state, pageStack) {
		state.pageStack = pageStack
	},
	setSyncModels(state, syncModels) {
		state.syncModels = syncModels
	},
	setSettings(state, settings) {
		state.settings = {
			...state.settings,
			...settings
		}

		this.$bridge.setLocalStorage('settings', JSON.stringify(state.settings))
	},
	resetCashDrawer(state) {
		state.cashDrawer = {
			show: false,
			close: true,
			type: ''
		}
	},
	setSelectedCategoryId(state, categoryId) {
		state.selectedCategoryId = categoryId
	},
	setSelectedVariation(state, variation) {
		state.selectedVariation = variation
	},
	setItemPopover(state, data) {
		state.itemPopover = data
	},
	setShowModifiers(state, showModifiers) {
		state.showModifiers = showModifiers
	},
	setCart(state, cart) {
		state.cart = Object.assign({}, state.cart, cart)
	},
	resetCart(state) {
		state.cart = {
			items: [],
			price: {
				subTotal: 0,
				tax: 0,
				discount: 0,
				total:0
			},
			watch: true
		}
	},
	setPrinterSettings(state, data) {
		state.printerSettings = data
		this.$bridge.setLocalStorage('printerSettings', JSON.stringify(data))
	},
	resetPrinterSettings(state) {
		state.printerSettings = {
			paperSize: 2,
			cutPaperAfterPrint: true,
			openCashboxAfterPrint: false,
			printShiftSummaryAfterClockout: true,
			printShiftSummaryCategoryItems: false,
			printShiftSummaryDenominations: false,
			reprintKot: false
		}
	},
	setShowDiscount(state, data) {
		state.showDiscount = data
	},
	setOnlineOrder(state,order){
		state.onlineOrder = order
	},
	setEzetap(state, data) {
		state.ezetap = Object.assign({}, state.ezetap, data)
		this.$bridge.setLocalStorage('ezetap', JSON.stringify(state.ezetap))
	},
	setmSwipe(state,data){
		state.mSwipe = Object.assign({}, state.mSwipe, data)
		this.$bridge.setLocalStorage('mSwipe', JSON.stringify(state.mSwipe))
	},
	resetEzetap(state) {
		state.ezetap = {
			enabled: false,
			credentials: state.ezetap.credentials
		}
		this.$bridge.setLocalStorage('ezetap', JSON.stringify(state.ezetap))
	},
	resetmSwipe(state){
		state.mSwipe = {
			enabled: false,
			credentials: state.mSwipe.credentials
		}
		this.$bridge.setLocalStorage('mSwipe', JSON.stringify(state.mSwipe))
	},
	setSelectedState(state, selectedStateValue) {
		state.onlineSelectedState = selectedStateValue
	},
	setSelectedChannel(state, selectedChannelValue) {
		state.onlineSelectedChannel = selectedChannelValue
	},
	setSelectedOnlineOrder(state, selectedOnlineOrder) {
		state.selectedOnlineOrder = selectedOnlineOrder
	},
	setAppMode(state, appMode){
		state.appMode = appMode
	},
	setPasscode(state, data) {
		if ("show" in data)
			state.passcode.show = data.show

		if ("value" in data)
			state.passcode.value = data.value

		if ("type" in data)
			state.passcode.type = data.type
	},
	resetPasscode(state) {
		state.passcode = {
			show: false,
			value: '',
			type: ''
		}
	},
	setEzSwype(state, status){
		state.isEzSwype = status
		this.$bridge.setLocalStorage('isEzSwype', status)
	},
	setShowVariablePriceModel(state, status){
		state.showVariablePriceModel = status
	},
	setShowCustomSale(state, status){
		state.showCustomSale = status
	}
}

export default mutations
