<template>
	<v-ons-page id="cart">
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Cart
			</div>
			<div class="right">
				<v-ons-toolbar-button
					v-if="source !== 'table'"
					:disabled="cart.items.length === 0"
					icon="md-delete"
					@click="emptyCart"
				/>
				<v-ons-toolbar-button
					v-if="source === 'table'"
					icon="md-print"
					@click="printReceipt"
				/>
				<v-ons-toolbar-button
					:disabled="cart.items.length === 0"
					icon="md-more-vert"
					@click="showPopover($event)"
				/>
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<v-ons-popover
			:visible.sync="popoverVisible"
			:target="popoverTarget"
			cancelable
		>
			<v-ons-list>
				<v-ons-list-item
					tappable
					@click="applyDiscount"
				>
					<div class="center">
						Apply Discount
					</div>
				</v-ons-list-item>
				<v-ons-list-item
					v-if="selectedPriceCategory.custom_attributes &&
						selectedPriceCategory.custom_attributes.labels &&
						selectedPriceCategory.custom_attributes.labels.length"
					tappable
					@click="showAdditionalInfo"
				>
					<div class="center">
						Add Additional Info
					</div>
				</v-ons-list-item>
			</v-ons-list>
		</v-ons-popover>
		<section class="h-100">
			<div
				v-if="cart.items.length"
				class="position-relative"
			>
				<v-ons-list style="padding-bottom: 84px">
					<v-ons-list-header class="px-2 pb-0">
						{{ cart.items.length }} Item{{ cart.items.length > 1 ? 's' : '' }}
					</v-ons-list-header>
					<v-ons-list-item
						v-for="(itemVariation, index) in cart.items"
						:key="index"
					>
						<v-ons-row>
							<v-ons-col
								width="60%"
								vertical-align="center"
							>
								<h4>
									{{ itemVariation.name }}
								</h4>
								<div
									v-if="itemVariation.groups && itemVariation.groups.length"
								>
									{{ itemVariation.groups.reduce((combos, g) => {
										if (g.type === 'combo')
											combos.push(g.item_variation_name)

										return combos
									}, []).join(', ') }}
								</div>

								<small class="d-block text-muted">
									{{ itemVariation.subTotal | currency({
										symbol: merchant.currencySymbol
									}) }}
								</small>
								<small
									v-if="itemVariation.mrp"
									class="d-block"
								>
									MRP: {{ itemVariation.mrp | currency({
										symbol: merchant.currencySymbol
									}) }}
								</small>
							</v-ons-col>
							<v-ons-col vertical-align="center">
								<v-ons-row class="add-item-btn">
									<v-ons-col>
										<v-ons-button
											class="w-100 rounded-left shadow-none"
											@click="$store.dispatch('modifyCart', {
												item: {
													id: itemVariation.item_id,
													name: itemVariation.item_name,
													category_id: itemVariation.category_id
												},
												variation: {
													id: itemVariation.id,
													inventory_id: itemVariation.inventory_id,
													kot_device_id: itemVariation.kot_device_id,
													name: itemVariation.name,
													sku: itemVariation.sku,
													price: itemVariation.price,
													quantity: itemVariation.quantity - 1,
													tax: itemVariation.tax,
													discount: itemVariation.discount,
													itemization_type: itemVariation.itemization_type,
													custom_attributes: {
														alternate_name: itemVariation.alternate_name
													},
													groups: itemVariation.groups
												}
											})"
										>
											<v-ons-icon icon="md-minus" />
										</v-ons-button>
									</v-ons-col>
									<v-ons-col vertical-align="center">
										<input
											v-model="itemVariation.quantity"
											type="number"
											class="w-100 border border-left-0 border-right-0 text-center"
											style="min-height: 32px"
											@change="$event => {
												$store.dispatch('modifyCart', {
													item: {
														id: itemVariation.item_id,
														name: itemVariation.item_name,
														category_id: itemVariation.category_id
													},
													variation: {
														id: itemVariation.id,
														inventory_id: itemVariation.inventory_id,
														kot_device_id: itemVariation.kot_device_id,
														name: itemVariation.name,
														sku: itemVariation.sku,
														price: itemVariation.price,
														quantity: itemVariation.quantity + 1,
														tax: itemVariation.tax,
														discount: itemVariation.discount,
														itemization_type: itemVariation.itemization_type,
														custom_attributes: {
															alternate_name: itemVariation.alternate_name
														},
														groups: itemVariation.groups
													}
												})
											}"
										>
									</v-ons-col>
									<v-ons-col>
										<v-ons-button
											class="w-100 rounded-right shadow-none"
											@click="$store.dispatch('modifyCart', {
												item: {
													id: itemVariation.item_id,
													name: itemVariation.item_name,
													category_id: itemVariation.category_id
												},
												variation: {
													id: itemVariation.id,
													inventory_id: itemVariation.inventory_id,
													kot_device_id: itemVariation.kot_device_id,
													name: itemVariation.name,
													sku: itemVariation.sku,
													price: itemVariation.price,
													quantity: itemVariation.quantity + 1,
													tax: itemVariation.tax,
													discount: itemVariation.discount,
													itemization_type: itemVariation.itemization_type,
													custom_attributes: {
														alternate_name: itemVariation.alternate_name
													},
													groups: itemVariation.groups
												}
											})"
										>
											<v-ons-icon icon="md-plus" />
										</v-ons-button>
									</v-ons-col>
								</v-ons-row>
							</v-ons-col>
						</v-ons-row>
					</v-ons-list-item>
				</v-ons-list>
				<section
					class="position-fixed bg-white border-top p-2"
					style="left: 0; bottom: 52px; right: 0"
				>
					<v-ons-row>
						<v-ons-col
							width="60%"
							vertical-align="center"
						>
							Subtotal
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<span>:</span>
							<span class="float-right">
								{{ cart.price.subTotal | currency({
									symbol: merchant.currencySymbol
								}) }}
							</span>
						</v-ons-col>
					</v-ons-row>
					<v-ons-row>
						<v-ons-col
							width="60%"
							vertical-align="center"
						>
							Tax
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<span>:</span>
							<span class="float-right">
								{{ cart.price.tax | currency({
									symbol: merchant.currencySymbol
								}) }}
							</span>
						</v-ons-col>
					</v-ons-row>
					<v-ons-row v-if="cart.price.discount > 0">
						<v-ons-col
							width="60%"
							vertical-align="center"
						>
							Discount
							<v-ons-toolbar-button
								icon="md-close-circle"
								class="p-0 m-0"
								style="font-size: 15px"
								@click="selectedDiscount = null"
							/>
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<span>:</span>
							<span class="float-right">
								{{ -cart.price.discount | currency({
									symbol: merchant.currencySymbol
								}) }}
							</span>
						</v-ons-col>
					</v-ons-row>
					<v-ons-row v-if="cart.price.roundOff">
						<v-ons-col
							width="60%"
							vertical-align="center"
						>
							Round Off
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<span>:</span>
							<span class="float-right">
								{{ cart.price.roundOff | currency({
									symbol: merchant.currencySymbol
								}) }}
							</span>
						</v-ons-col>
					</v-ons-row>
				</section>
			</div>
			<v-ons-row
				v-else
				class="h-100 text-muted"
			>
				<v-ons-col
					vertical-align="center"
					class="text-center"
				>
					<v-ons-icon
						icon="fa-cart-plus"
						size="3x"
					/>
					<h4>Cart is Empty!</h4>
				</v-ons-col>
			</v-ons-row>
		</section>
		<v-ons-bottom-toolbar>
			<v-ons-row class="h-100 px-2">
				<v-ons-col vertical-align="center">
					<h4 class="m-0">
						Total: {{ cart.price.total | currency({
							symbol: merchant.currencySymbol
						}) }}
					</h4>
				</v-ons-col>
				<v-ons-col
					v-if="source === 'table'"
					vertical-align="center"
				>
					<v-ons-button
						class="bg-danger float-right shadow-none mr-1"
						@click="cancelOrder"
					>
						CANCEL
					</v-ons-button>
				</v-ons-col>
				<v-ons-col
					v-else
					vertical-align="center"
				>
					<v-ons-button
						class="bg-danger float-right shadow-none mr-1"
						:disabled="cart.items.length === 0"
						@click="holdCart"
					>
						HOLD
					</v-ons-button>
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<v-ons-button
						class="float-right shadow-none"
						:disabled="cart.items.length === 0"
						@click="proceedToCheckout"
					>
						CHECKOUT
					</v-ons-button>
				</v-ons-col>
			</v-ons-row>
		</v-ons-bottom-toolbar>
		<discounts />
	</v-ons-page>
</template>

<script>
	import Sell from '~/components/sell'
	import Checkout from '~/components/checkout'
	import AdditionalInfo from '~/components/additional-info'
	import Subscription from '~/components/subscription'

	export default {
		name: 'Cart',
		props: {
			source: {
				type: String,
				required: true
			},
			orderProp: {
				type: Object,
				default() {
					return {}
				}
			},
			tableOrders: {
				type: Array,
				default() {
					return []
				}
			}
		},
		data() {
			return {
				popoverVisible: false,
				popoverTarget: null
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			merchant() {
				return this.$store.state.merchant
			},
			cart() {
				return this.$store.state.cart
			},
			discountsList() {
				return this.$store.getters.orderDiscounts
			},
			onHold() {
				return this.$store.state.onHold
			},
			selectedPriceCategory() {
				return this.$store.state.selectedPriceCategory
			},
			showDiscount: {
				get() {
					return this.$store.state.showDiscount
				},
				set(data) {
					this.$store.commit('setShowDiscount', data)
				}
			},
			selectedDiscount: {
				get() {
					return this.$store.state.selectedDiscount
				},
				set(discount) {
					this.$store.commit('setState', {
						key: 'selectedDiscount',
						value: discount
					})
					this.$store.dispatch('cartCalculation')
				}
			},
			subscription: {
				get() {
					return this.$store.state.subscription
				},
				set(value) {
					this.$store.commit('setState', {
						key: 'subscription',
						save: true,
						value
					})
				}
			}
		},
		watch:{
			selectedDiscount(discount){
				let totalPrice = this.orderProp.sub_total + this.orderProp.total_tax - this.cart.price.discount
				this.tableOrder = {
					...this.orderProp,
					total_price: totalPrice,
					total_discount: this.cart.price.discount,
					discounts: [discount],
					round_off_amount: this.cart.price.roundOff
				}
				this.updateOrder({
					id: this.tableOrder.id,
					total_price: this.tableOrder.total_price,
					total_discount: this.tableOrder.total_discount,
					discounts: JSON.stringify(this.tableOrder.discounts),
					round_off_amount: this.tableOrder.round_off_amount,
					updated_at: new Date(),
					refresh: false
				})

				this.orderProp = this.tableOrder
			}
		},
		beforeMount(){
			if (this.subscription.is_trial && +this.$bridge.getLocalStorage('orderCount') >= this.subscription.limitations.order) {
				this.$store.commit('setState', {
					key: 'subscription',
					value: {
						...this.subscription,
						show_modal: true
					}
				})

				return
			}
		},
		mounted() {
			document.querySelector('ons-back-button').onClick = (event) => {
				if(this.source === 'table'){
					this.pageStack.pop()
					this.$store.commit('resetCart')
				} else {
					this.pageStack.push({
						extends: Sell
					})
				}
			};
		},
		methods: {
			showPopover($event) {
				this.popoverVisible = true
				this.popoverTarget = $event
			},
			emptyCart() {
				this.$ons.notification
					.confirm('Are you sure you want to remove all items from your cart?', {
						title: 'Empty Cart'
					})
					.then(response => {
						if (response)
							this.$store.commit('resetCart')
					})
			},
			proceedToCheckout() {
				this.$emit('push', {
					extends: this.subscription.show_modal ? Subscription : Checkout,
					onsNavigatorProps: {
						source: this.source,
						orderProp: this.orderProp
					}
				})
			},
			showAdditionalInfo() {
				this.popoverVisible = false
				this.$emit('push', AdditionalInfo)
			},
			holdCart() {
				this.$ons.notification
					.confirm('You want to hold this sale?', {
						title: 'Are you sure?'
					})
					.then(response => {
						if (response) {
							const onHold = this.onHold.slice()

							this.cart.createdAt = new Date()
							onHold.unshift(this.cart)
							this.$store.commit('setState', {
								key: 'onHold',
								value: onHold,
								save: true
							})
							this.$store.commit('resetCart')
							this.pageStack.pop()
						}
					})
			},
			cancelOrder(){
				this.$ons.notification
					.confirm('You want to cancel this order?', {
						title: 'Are you sure?'
					})
					.then(response => {
						if (response) {
							let tableOrders = this.$bridge.getOrders(this.deviceId, JSON.stringify({
								table_id: this.orderProp.tables.map(t => t.id),
								status: ['pending', 'billed']
							}))

							tableOrders = ( typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders ).data

							const order = {
								id: this.orderProp.id,
								status: 'cancelled',
								tables: this.orderProp.tables.map(table => {
									const orders = tableOrders.filter(o => {
										return o.tables.findIndex(t => t.id === table.id) !== -1
									})

									return {
										...table,
										custom_attributes: '{}',
										is_occupied: tableOrders.length > 1
									}
								}),
								updated_at: new Date(),
								is_synced: true
							}
							this.$store.commit('resetCart')
							this.$bridge.insert('Order', this.$bridge.getName() === 'ANDROID' ?
								JSON.stringify(order) : order, true)
							this.pageStack.pop()
						}
				})
			},
			printReceipt() {
				this.updateOrder({
					id: this.orderProp.id,
					status: 'billed',
					updated_at: new Date()
				})

				this.$store.commit('setState', {
						key: 'orderReceipt',
						value: {
							print: true,
							type: 'order',
							data: {
								...this.orderProp,
								customers: this.orderProp.customer ? [this.orderProp.customer] : []
							}
						}
				})
			},
			updateOrder(order) {
				this.$bridge.insert('Order', this.$bridge.getName() === 'ANDROID' ?
					JSON.stringify(order) : order, true)
			},
			applyDiscount(){
				this.popoverVisible = false
				if(this.discountsList && this.discountsList.length > 0){
					this.showDiscount = true
				} else {
					this.$ons.notification.toast('No discounts available', {
						timeout: 2000
					})
				}
			}
		}
	}
</script>

<style lang="scss">
	#cart {
		.page__content {
			bottom: 52px;
		}

		.bottom-bar {
			height: 52px;
		}

		.list-item {
			padding-left: 0;

			&__center {
				padding: .5rem;
			}
		}

		.list-item .ripple__wave {
			background: rgba(255, 255, 255, 0.2);
		}
	}
</style>
