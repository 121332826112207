<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				{{ selectedItem.name }}
			</div>
			<div class="right">
				<v-ons-toolbar-button
					icon="md-shopping-cart"
					class="position-relative mr-3"
					@click="showCart"
				>
					<span
						v-if="cart.items.length"
						class="notification notification--material position-absolute"
						style="top: 5px"
					>
						{{ cart.items.length }}
					</span>
				</v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>
		<v-ons-card
			v-for="(variation, index) in selectedItem.variations"
			:key="index"
			class="p-2"
			@click="() => {
				selectedVariation = variation
				$store.commit('setShowVariablePriceModel', true)
			}"
		>
			<v-ons-row class="mb-1">
				<v-ons-col
					width="80%"
					vertical-align="center"
				>
					<div>
						<h4 class="text-capitalize m-0">
							{{ variation.name }}
						</h4>
						<div
							v-if="variation.combo && variation.combo.variations.length"
							class="text-capitalize mb-1"
						>
							<small>
								{{ variation.combo.variations.map(v => v.name).join(', ') }}
							</small>
						</div>
					</div>
				</v-ons-col>
				<v-ons-col>
					<v-ons-toolbar-button
						icon="md-more-vert"
						class="float-right p-0 m-0"
						@click.stop="showItemPopover(variation, $event)"
					/>
				</v-ons-col>
			</v-ons-row>
			<v-ons-row>
				<v-ons-col
					width="60%"
				>
					<small class="text-muted">
						<span class="d-block">
							SKU: {{ variation.sku }}
						</span>
						<span>
							{{ variation.price | currency({
								symbol: merchant.currencySymbol
							}) }} <span
								v-if="variation.pricing_type === 'variable'"
								class="text-danger"
							>*
							</span>
						</span>
						<span
							v-if="variation.custom_attributes.mrp"
							class="d-block"
						>
							MRP: {{ variation.custom_attributes.mrp | currency({
								symbol: merchant.currencySymbol
							}) }}
						</span>
					</small>
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<v-ons-row
						v-if="cartItems[`${variation.id}-${variation.price}`]"
						class="add-item-btn"
					>
						<v-ons-col>
							<v-ons-button
								class="w-100 rounded-left shadow-none"
								@click.stop="() => {
									variation.quantity = cartItems[`${variation.id}-${variation.price}`].quantity - 1
									$store.dispatch('modifyCart', {
										item: {
											id: selectedItem.id,
											name: selectedItem.name,
											category_id: selectedItem.category.id
										},
										variation: variation
									})
								}"
							>
								<v-ons-icon icon="md-minus" />
							</v-ons-button>
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<input
								:value="cartItems[`${variation.id}-${variation.price}`].quantity"
								type="number"
								class="w-100 border border-left-0 border-right-0 text-center"
								style="min-height: 32px"
								readonly
								@click.stop="() => {
									selectedVariation = variation
									$store.commit('setShowVariablePriceModel', true)
								}"
							>
						</v-ons-col>
						<v-ons-col>
							<v-ons-button
								class="w-100 rounded-right shadow-none"
								@click.stop="() => {
									variation.quantity = cartItems[`${variation.id}-${variation.price}`].quantity + 1
									$store.dispatch('modifyCart', {
										item: {
											id: selectedItem.id,
											name: selectedItem.name,
											category_id: selectedItem.category.id
										},
										variation: variation
									})
								}"
							>
								<v-ons-icon icon="md-plus" />
							</v-ons-button>
						</v-ons-col>
					</v-ons-row>
					<v-ons-button
						v-else
						class="w-100 shadow-none"
						@click.stop="() => {
							variation.quantity = 1
							$store.dispatch('modifyCart', {
								item: {
									id: selectedItem.id,
									name: selectedItem.name,
									category_id: selectedItem.category.id
								},
								variation: variation
							})
						}"
					>
						Add Item
					</v-ons-button>
					<p
						v-if="variation && variation.modifiers.length"
						class="text-center m-0 mt-1"
						style="font-size:10px;"
					>
						Customizable
					</p>
				</v-ons-col>
			</v-ons-row>
		</v-ons-card>
		<price-variable-dialog />
	</v-ons-page>
</template>

<script>
	import Cart from '~/components/cart'
	export default {
		computed: {
			merchant() {
				return this.$store.state.merchant
			},
			selectedItem() {
				return this.$store.state.selectedItem
			},
			cart() {
				return this.$store.state.cart
			},
			selectedVariation: {
				get() {
					return this.$store.state.selectedVariation
				},
				set(variation) {
					this.$store.commit('setSelectedVariation', variation)
				}
			},
			cartItems() {
				const cartItems = {}

				this.$store.state.cart.items.forEach(i => cartItems[`${i.id}-${i.price}`] = i)

				return cartItems
			},
		},
		methods: {
			showItemPopover(variation, $event) {
				this.selectedVariation = variation
				this.$store.commit('setItemPopover', {
					show: true,
					target: $event
				})
			},
			showCart() {
				this.$emit('push', {
					extends: Cart,
					onsNavigatorProps: {
						source: 'sell'
					}
				})
			},
		}
	}
</script>
