<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Add Customer
			</div>
			<div class="right">
				<v-ons-toolbar-button
					v-if="selectedCustomer.id"
					icon="md-delete"
					@click="resetSelectedCustomer"
				/>
			</div>
		</v-ons-toolbar>
		<section
			class="position-relative h-100"
			:class="{
				'bg-white': customers.length === 0
			}"
		>
			<div
				class="position-fixed p-2"
				:style="{
					left: 0,
					right: 0,
					backgroundColor: '#eee',
					zIndex: 1
				}"
			>
				<v-ons-search-input
					id="customer-search"
					ref="searchTerm"
					placeholder="Search by name / phone"
					class="w-100"
					@keyup="getCustomers($event.currentTarget.value.trim())"
				/>
			</div>
			<section
				v-if="customers.length"
				style="padding-top: 64px"
			>
				<v-ons-card
					v-for="(customer, index) in customers"
					:key="index"
					:class="{
						'mt-0': index === 0
					}"
					tappable
					@click="selectCustomer(customer)"
				>
					<v-ons-ripple />
					<v-ons-row>
						<v-ons-col>
							<p class="text-capitalize m-0">
								{{ customer.first_name + ' ' + (customer.last_name || '') }}
							</p>
							<small>{{ customer.phone | mask('mobile') }}</small>
						</v-ons-col>
						<v-ons-col class="text-center">
							<p class="m-0">
								Limit
							</p>
							<small>{{ customer.credit_limit | currency({
								symbol: merchant.currencySymbol
							}) }}</small>
						</v-ons-col>
						<v-ons-col class="text-center">
							<p class="m-0">
								Outstanding
							</p>
							<small>{{ (customer.credit && !customer.debit ? customer.credit : (customer.credit - customer.debit)) | currency({
								symbol: merchant.currencySymbol
							}) }}</small>
						</v-ons-col>
					</v-ons-row>
				</v-ons-card>
			</section>
			<v-ons-list
				v-else
				class="pb-5"
				style="padding-top: 64px"
			>
				<v-ons-list-header class="pb-0 pr-2">
					Add New Customer
				</v-ons-list-header>
				<ValidationObserver ref="validator">
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="first_name"
							rules="required"
							class="w-100"
						>
							<v-ons-input
								v-model="selectedCustomer.first_name"
								type="text"
								name="first_name"
								placeholder="First Name *"
								float
								class="w-100"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validationprovider>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model="selectedCustomer.last_name"
							type="text"
							name="last_name"
							placeholder="Last Name"
							class="w-100"
							float
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="phone"
							:rules="{
								required: true,
								mobile: merchant.countryCode
							}"
							class="w-100"
						>
							<v-ons-input
								v-model="selectedCustomer.phone"
								type="number"
								name="phone"
								class="w-100"
								placeholder="Mobile Number *"
								maxlength="10"
								float
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validationprovider>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="email"
							rules="required"
							class="w-100"
						>
							<v-ons-input
								v-model="selectedCustomer.email"
								type="email"
								name="email"
								placeholder="Email *"
								float
								class="w-100"
							/>
							<span class="text-danger">{{ errors[0] }}</span>
						</validationprovider>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="code"
							class="w-100"
							rules="'alpha_num'"
						>
							<v-ons-input
								v-model="selectedCustomer.code"
								type="text"
								name="code"
								placeholder="Customer Code"
								class="w-100"
								float
							/>
							<span class="text-danger">{{ errors[0] }}</span>
						</validationprovider>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model="selectedCustomer.custom_attributes.company_name"
							type="text"
							placeholder="Company Name"
							float
							class="w-100"
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="credit_limit"
							rules="decimal:2"
							class="w-100"
						>
							<v-ons-input
								v-model.number="selectedCustomer.credit_limit"
								type="number"
								name="credit_limit"
								placeholder="Credit Limit"
								float
								class="w-100"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validationprovider>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model="selectedCustomer.custom_attributes.trn"
							type="text"
							name="custom_attributes[trn]"
							placeholder="TRN"
							class="w-100"
							float
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model="selectedCustomer.address.residence.address_line1"
							type="text"
							name="address[residence][address_line1]"
							placeholder="Address Line 1"
							class="w-100"
							float
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model="selectedCustomer.address.residence.address_line2"
							type="text"
							name="address[residence][address_line2]"
							placeholder="Address Line 2"
							class="w-100"
							float
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model="selectedCustomer.address.residence.city"
							type="text"
							name="address[residence][city]"
							placeholder="City"
							class="w-100"
							float
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model="selectedCustomer.address.residence.region"
							type="text"
							name="address[residence][region]"
							placeholder="Region"
							class="w-100"
							float
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="address[residence][postal_code]"
							rules="numeric"
							class="w-100"
						>
							<v-ons-input
								v-model="selectedCustomer.address.residence.postal_code"
								type="number"
								placeholder="Postal Code"
								float
								class="w-100"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validationprovider>
					</v-ons-list-item>
				</validationobserver>
			</v-ons-list>
			<v-ons-button
				v-if="customers.length === 0"
				class="position-fixed rounded-0"
				style="bottom: 0; left: 0; right: 0"
				@click="submitForm"
			>
				Add
			</v-ons-button>
		</section>
	</v-ons-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate"

	const initCustomer = {
		phone: '',
		first_name: '',
		last_name: '',
		credit_limit: '',
		email: '',
		code: '',
		address: {
			residence: {
				address_line1: '',
				address_line2: '',
				city: '',
				region: '',
				postal_code: ''
			}
		},
		custom_attributes: {
			company_name: '',
			trn: ''
		}
	}

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			customerProp: {
				type: Object,
				default() {
					return initCustomer
				}
			}
		},
		data() {
			return {
				customers: [],
				selectedCustomer: Object.assign({}, this.customerProp)
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			merchant() {
				return this.$store.state.merchant
			}
		},
		methods: {
			getCustomers(searchTerm) {
				if (searchTerm) {
					const customers = this.$bridge.getCustomers(
						this.deviceId,
						JSON.stringify({ search_term: searchTerm })
					)

					this.customers = (
						typeof customers === 'string' ? JSON.parse(customers) : customers
					).data
				} else {
					this.customers = []
				}
			},
			selectCustomer(customer) {
				this.selectedCustomer = customer
				this.$root.$emit('updateCustomer', customer)
				this.pageStack.pop()
			},
			resetSelectedCustomer() {
				this.selectedCustomer = initCustomer
				this.$root.$emit('updateCustomer', initCustomer)
			},
			async submitForm() {
				if(await this.$refs.validator.validate()){
						let customer = this.$bridge.getCustomers(this.deviceId, JSON.stringify({
							search_term: this.selectedCustomer.phone
						}))

						customer = (typeof customer === 'string' ?
							JSON.parse(customer) : customer).data[0]

						if (customer && customer.id !== this.selectedCustomer.id) {
							this.$ons.notification.toast('Mobile number already exists.', {
								timeout: 3000
							})
							return;
						}

						customer = {
							id: parseInt(this.selectedCustomer.phone),
							device_id: this.deviceId,
							customer_id: this.selectedCustomer.customer_id,
							first_name: this.selectedCustomer.first_name,
							last_name: this.selectedCustomer.last_name,
							code: this.selectedCustomer.code,
							email: this.selectedCustomer.email,
							phone: this.selectedCustomer.phone,
							address: JSON.stringify(this.selectedCustomer.address),
							credit_limit: this.selectedCustomer.credit_limit || 0,
							credit: this.selectedCustomer.credit || 0,
							debit: this.selectedCustomer.debit || 0,
							custom_attributes: JSON.stringify(this.selectedCustomer.custom_attributes),
							updated_at: new Date(),
							is_active: true
						}

						if (!this.selectedCustomer.id)
							this.selectedCustomer.id = customer.id

						if (this.selectedCustomer.id && this.selectedCustomer.id != this.selectedCustomer.phone) {
							this.$bridge.updateCustomerPhone(this.selectedCustomer.id, customer.phone)
							this.selectedCustomer.alternative_phone = this.selectedCustomer.id
							this.selectedCustomer.id = customer.id
						}

						this.$bridge.insert(
							'Customer',
							this.$bridge.getName() === 'ANDROID' ? JSON.stringify(customer) : customer,
							true
						)
						this.selectCustomer(this.selectedCustomer)
				}
			}
		}
	}
</script>
